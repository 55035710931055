import logo from './logo.svg';
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Sop from './pages/sop';
import Products from './pages/products';
import Events from './pages/events';
import ShiftNotes from './pages/shiftnotes';
import Metrics from './pages/metrics';
import Impacts from './pages/impacts';
import ProductCount from './pages/productcount';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

function App(signOut, user ) {
  return (

    <>
      <button onClick={signOut}>Sign out</button>
    
        <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/sop' element={<Sop/>} />
        <Route path='/products' element={<Products/>} />
        <Route path='/events' element={<Events/>} />
        <Route path='/shiftnotes' element={<ShiftNotes/>} />
        <Route path='/metrics' element={<Metrics/>} />
        <Route path='/impacts' element={<Impacts/>} />
        <Route path='/productcount' element={<ProductCount/>} />
      </Routes>
    </Router>
    </>
  );
}

export default withAuthenticator(App);