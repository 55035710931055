import React from 'react';
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />

		<NavMenu>
    <NavLink to='/' >
			Home
		</NavLink>
		<NavLink to='/sop' >
			SOPs
		</NavLink>
		<NavLink to='/products' >
			Products
		</NavLink>
		<NavLink to='/events' >
			Events
		</NavLink>
		<NavLink to='/shiftnotes' >
			Shift Notes
		</NavLink>
		<NavLink to='/metrics' >
			Metrics
		</NavLink>
		<NavLink to='/impacts' >
			Impacts
		</NavLink>
		<NavLink to='/productcount' >
			Product
			Count
		</NavLink>
		{/* Second Nav */}
		{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
		</NavMenu>
		<NavBtn>
		<NavBtnLink to='/signin'>Sign In</NavBtnLink>
		</NavBtn>
	</Nav>
	</>
);
};

export default Navbar;