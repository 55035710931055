import React from 'react';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import MyModal from '../components/util/modal';
import Table from '../components/util/table';

const Sop = () => {

return (

	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				Standard Operating Procedures
			</h1>
		</div>
		<div>
			<h1>
			<Table class="center_table"></Table>
			</h1>
		</div>
	
	</div>
);
};

export default Sop;