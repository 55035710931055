import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: 'AKIA46WRLH3YW3JHLFPG',
  secretAccessKey: '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',
  region: 'us-east-1'
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

function MyCalendar() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const params = {
      TableName: 'oss-events',
      ProjectionExpression: 'title, start_date, end_date'
    };

    dynamodb.scan(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const events = data.Items.map((item) => ({
          title: item.title,
          start: moment(item.start_date).toDate(),
          end: moment(item.end_date).toDate()
        }));
        setEvents(events);
      }
    });
  }, []);

  const localizer = momentLocalizer(moment);

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ flex: '1' }}
      />
    </div>
    
  );
}

export default MyCalendar;