import React from 'react';
import MetricsTable from '../components/util/metrics';
import MetricsForm from '../components/util/dynamoput_metrics';

const Metrics = () => {
return (
	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				QA Log
			</h1>
		</div>
		<div>
			<h3>
			<MetricsTable class="center_table"></MetricsTable>
			</h3>
		</div>
		<div>
			<h3>
			<MetricsForm class="center_table"></MetricsForm>
			</h3>
		</div>
	
	</div>
);
};

export default Metrics;