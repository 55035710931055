import React, { useState } from 'react';
import AWS from 'aws-sdk';

const dynamodb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'AKIA46WRLH3YW3JHLFPG',
    secretAccessKey: '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',
  },
});

const ChatForm = () => {
  const [text, setText] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const date = new Date();
      const isoDate = date.toISOString();
      const params = {
        TableName: 'osw_chat', // Replace with your table name
        Item: {
          chat_body: { S: text }, // Replace with your item ID
          date_time: { S: isoDate },
          user_name: { S: selectedOption }
        },
      };
      await dynamodb.putItem(params).promise();
      console.log('Data inserted successfully!');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="">-- Select a user --</option>
        <option value="Bill">Bill</option>
        <option value="Keven">Keven</option>
        <option value="John">John</option>
      </select>
      <label>
        Log Entry:
        <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default ChatForm;
