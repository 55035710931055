import React from 'react';
import '../../css/util/Table.css';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import MyModal from './modal';

const MefTable = () => {

  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  function openModal1() {setModalIsOpen1(true);}
  function closeModal1() {setModalIsOpen1(false);}

  return (
    <table className="table">
      <tr>
        <td colSpan="3">Mission Execution Forecast Product</td>
      </tr>
      <tr>
        <td><a href="https://www.weather.gov/oax/weatherstory">NWS Impact Hazardous Weather chart Omaha</a></td>
        <td>
        <button onClick={openModal1}>Hazardous Weather chart Omaha</button>
              <MyModal isOpen={modalIsOpen1} onClose={closeModal1} title="Hazardous Weather chart Omaha">
              <img src="https://www.weather.gov/images/oax/wxstory/Tab2FileL.png?5fa7291b286d9b513d6b3c67d4e2250c" alt="Image" />
              </MyModal>
        </td>
      </tr>
      <tr>
        <td><a href="https://www.weather.gov/oax/weatherstory">NWS Impact Hazardous Weather chart Iowa</a></td>
        <td>
        <button onClick={openModal1}>Hazardous Weather chart Iowa</button>
              <MyModal isOpen={modalIsOpen1} onClose={closeModal1} title="Hazardous Weather chart Iowa">
              <img src="https://www.weather.gov/images/oax/wxstory/Tab2FileL.png?5fa7291b286d9b513d6b3c67d4e2250c" alt="Image" />
              </MyModal>
        </td>
      </tr>
      <tr>
        <td><a href="https://www.weather.gov/oax/weatherstory">NWS Impact Hazardous Weather chart Buffalo</a></td>
        <td>
        <button onClick={openModal1}>Hazardous Weather chart Buffalo</button>
              <MyModal isOpen={modalIsOpen1} onClose={closeModal1} title="Hazardous Weather chart Buffalo">
              <img src="https://www.weather.gov/images/oax/wxstory/Tab2FileL.png?5fa7291b286d9b513d6b3c67d4e2250c" alt="Image" />
              </MyModal>
        </td>
      </tr>
      <tr>
        <td>Download all Charts</td>
        <td>
        <button onClick={openModal1}>Download all Charts</button>
              <MyModal isOpen={modalIsOpen1} onClose={closeModal1} title="Download all Charts">
              <img src="https://www.weather.gov/images/oax/wxstory/Tab2FileL.png?5fa7291b286d9b513d6b3c67d4e2250c" alt="Image" />
              </MyModal>
        </td>
      </tr>
    </table>
  );
};

export default MefTable;