import React, { useState, useEffect } from 'react';

function MetricsTable() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('https://8pcbnvbki4.execute-api.us-east-1.amazonaws.com/v0/get_metrics');
      const jsonData = await response.json();
      setData(jsonData);
    }
    fetchData();
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Date Time</th>
          <th>User</th>
          <th>QA Log</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.user}>
            <td>{item.date_time}</td>
            <td>{item.user}</td>
            <td>{item.error}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default MetricsTable;