import React from 'react';
import Dropdown from '../components/util/user_dropdown';
import ProductCountForm from '../components/util/dynamoput_productcount';

const ProductCount = () => {
return (
	<div>
		<br></br>
		<h1>Insert Product count</h1>
		<div>
			<ProductCountForm></ProductCountForm>
		</div>
		<br></br>
		<h1>Product count retreival</h1>
		<div>
			<Dropdown></Dropdown>
		</div>
	</div>
);
};

export default ProductCount;