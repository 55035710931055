import React from 'react';

const Home = () => {
return (
	<div>
	<img src="https://s3.amazonaws.com/170ang.com/background.png"/>
	<img src="https://s3.amazonaws.com/170ang.com/background.png"/>
	</div>
);
};

export default Home;

