import React from 'react';
import Modal from 'react-modal';

function MyModal(props) {
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose}>
      <h2>{props.title}</h2>
      <div>{props.children}</div>
      <button onClick={props.onClose}>Close</button>
    </Modal>
  );
}

export default MyModal;