import React from 'react';
import Impactsc17 from '../components/util/impacts_c17';
import Impactshh60 from '../components/util/impacts_hh60';

const Impacts = () => {
return (
	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				Site Impacts
			</h1>
		</div>
		<div>
			<h3>
			<Impactsc17></Impactsc17>
			</h3>
		</div>
        <div>
			<h3>
			<Impactshh60></Impactshh60>
			</h3>
		</div>
	
	</div>
);
};

export default Impacts;