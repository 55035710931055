import React, { useState } from 'react';
import AWS from 'aws-sdk';
import Dropdown from './user_dropdown'

const dynamodb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'AKIA46WRLH3YW3JHLFPG',
    secretAccessKey: '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',
  },
});

const MetricsForm = () => {
  const [user_text, setUserText] = useState('');
  const [error_text, setErrorText] = useState('');
  const date = new Date();
  const isoDate = date.toISOString();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = {
        TableName: 'osw_metrics', // Replace with your table name
        Item: {
          user: { S: user_text }, // Replace with your item ID
          date_time: { S: isoDate },
          error: { S: error_text },
        },
      };
      await dynamodb.putItem(params).promise();
      console.log('Data inserted successfully!');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        User:
        <input type="text" value={user_text} onChange={(e) => setUserText(e.target.value)} />
      </label>
      <label>
        Error:
        <input type="text" value={error_text} onChange={(e) => setErrorText(e.target.value)} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default MetricsForm;
