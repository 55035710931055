import React, { useState } from 'react';

function Dropdown() {
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState([]);

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);

    // Fetch data from server based on selected option
    fetchData(event.target.value);
  }

  async function fetchData(option) {
    // Fetch data from server based on selected option
    const response = await fetch(`https://8pcbnvbki4.execute-api.us-east-1.amazonaws.com/v0/get_productcount?user=${option}`);
    const data = await response.json();

    setData(data);
  }

  return (
    <div>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="">-- Select an option --</option>
        <option value="Bill">Bill</option>
        <option value="Keven">Keven</option>
        <option value="John">John</option>
      </select>
      <table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Product Number</th>
          <th>User Name</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => {
          const { user_name, timestamp, product_type, product_count  } = item;
          return (
            <tr key={timestamp.S}>
              <td>{product_type.S}</td>
              <td>{product_count.S}</td>
              <td>{user_name.S}</td>
              <td>{timestamp.S}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
  );
}


export default Dropdown;
