import React from 'react';
import MyCalendar from '../components/util/calendar';

const Events = () => {
return (
	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				55 OSW Events
			</h1>
		</div>
		<div>
		<MyCalendar class="center_table"></MyCalendar>
		</div>
	
	</div>
);
};

export default Events;