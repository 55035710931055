import React from 'react';
import SevereTable from '../components/util/severetable';
import MEFTable from '../components/util/meftable';
import CommonLinkTable from '../components/util/commonlinktable';

const Products = () => {
return (
	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				Products
			</h1>
		</div>
		<div>
			<h3>
			<SevereTable class="center_table"></SevereTable>
			</h3>
		</div>
	<br></br>
	<br></br>
		<div>
			<h3>
			<MEFTable class="center_table"></MEFTable>
			</h3>
		</div>
		<br></br>
		<br></br>
		<div>
			<h3>
			<CommonLinkTable class="center_table"></CommonLinkTable>
			</h3>
		</div>
	
	</div>
);
};

export default Products;