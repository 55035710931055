import React from 'react';
import '../../css/util/Table.css';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import MyModal from './modal';

const Table = () => {

  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [modalIsOpen4, setModalIsOpen4] = useState(false);
  const [modalIsOpen5, setModalIsOpen5] = useState(false);
  const [modalIsOpen6, setModalIsOpen6] = useState(false);
  const [modalIsOpen7, setModalIsOpen7] = useState(false);
  const [modalIsOpen8, setModalIsOpen8] = useState(false);
  const [modalIsOpen9, setModalIsOpen9] = useState(false);

  function openModal1() {setModalIsOpen1(true);}
  function closeModal1() {setModalIsOpen1(false);}

  function openModal2() {setModalIsOpen2(true);}
  function closeModal2() {setModalIsOpen2(false);}

  function openModal3() {setModalIsOpen3(true);}
  function closeModal3() {setModalIsOpen3(false);}

  function openModal4() {setModalIsOpen4(true);}
  function closeModal4() {setModalIsOpen4(false);}

  function openModal5() {setModalIsOpen5(true);}
  function closeModal5() {setModalIsOpen5(false);}

  function openModal6() {setModalIsOpen6(true);}
  function closeModal6() {setModalIsOpen6(false);}

  function openModal7() {setModalIsOpen7(true);}
  function closeModal7() {setModalIsOpen7(false);}

  function openModal8() {setModalIsOpen8(true);}
  function closeModal8() {setModalIsOpen8(false);}

  function openModal9() {setModalIsOpen9(true);}
  function closeModal9() {setModalIsOpen9(false);}

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Forecast SOPs</th>
          <th>Observing SOPs</th>
          <th>COOP SOPs</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <button onClick={openModal1}>Forecast SOP 1</button>
              <MyModal isOpen={modalIsOpen1} onClose={closeModal1} title="Forecast SOP 1">
                <p>This is some content from Forecast SOP 1.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal2}>Observing SOP 1</button>
              <MyModal isOpen={modalIsOpen2} onClose={closeModal2} title="Observing SOP 1">
                <p>This is some content from Observing SOP 1.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal3}>Coop SOP 1</button>
              <MyModal isOpen={modalIsOpen3} onClose={closeModal3} title="Coop SOP 1">
                <p>This is some content from Coop SOP 1.</p>
              </MyModal>
          </td>
        </tr>
        <tr>
          <td>
            <button onClick={openModal4}>Forecast SOP 2</button>
              <MyModal isOpen={modalIsOpen4} onClose={closeModal4} title="Forecast SOP 2">
                <p>This is some content from Forecast SOP 2.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal5}>Observing SOP 2</button>
              <MyModal isOpen={modalIsOpen5} onClose={closeModal5} title="Observing SOP 2">
                <p>This is some content from Observing SOP 2.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal6}>Coop SOP 2</button>
              <MyModal isOpen={modalIsOpen6} onClose={closeModal6} title="Coop SOP 2">
                <p>This is some content from Coop SOP 2.</p>
              </MyModal>
          </td>
        </tr>
        <tr>
          <td>
            <button onClick={openModal7}>Forecast SOP 3</button>
              <MyModal isOpen={modalIsOpen7} onClose={closeModal7} title="Forecast SOP 3">
                <p>This is some content from Forecast SOP 3.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal8}>Observing SOP 3</button>
              <MyModal isOpen={modalIsOpen8} onClose={closeModal8} title="Observing SOP 3">
                <p>This is some content from Observing SOP 3.</p>
              </MyModal>
          </td>
          <td>
            <button onClick={openModal9}>Coop SOP 3</button>
              <MyModal isOpen={modalIsOpen9} onClose={closeModal9} title="Coop SOP 3">
                <p>This is some content from Coop SOP 3.</p>
              </MyModal>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;