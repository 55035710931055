import React from 'react';
import ChatTable from '../components/util/shiftnoteschat';
import ChatForm from '../components/util/dynamoput_shiftnotes';

const ShiftNotes = () => {
return (
	<div>
		<div>
			<h1 style={{display: 'flex',justifyContent: 'Center',alignItems: 'Right'}}>
				Shift Notes
			</h1>
		</div>
		<div>
			<h3>
			<ChatTable class="center_table"></ChatTable>
			</h3>
		</div>
		<div>
			<h3>
			<ChatForm class="center_table"></ChatForm>
			</h3>
		</div>
	
	</div>
);
};

export default ShiftNotes;