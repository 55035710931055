import React, { useState, useEffect } from 'react';

function Impactsc17() {
  const [jsonData, setJsonData] = useState('');
  const currentTime = new Date();
  currentTime.setMinutes(0);
  currentTime.setSeconds(0);
  currentTime.setMilliseconds(0);
  const isoTime = currentTime.toISOString().slice(0, -5);
  const timePlusTwentyFourHours = new Date(currentTime.getTime() + 8 * 60 * 60 * 1000);
  const isoTimeTewntyFour = timePlusTwentyFourHours.toISOString().slice(0, -5);

  useEffect(() => {
    async function fetchJsonData() {
      const response = await fetch('https://7rsyec26qh.execute-api.us-gov-west-1.amazonaws.com/v0/impacts/point', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          assets: [
            {
              asset_name: "pointImpactAsset",
              organization_id: "cyone"
            }
          ],
          point: [
            -10,
            10
          ],
          level_min: 2,
          level_max: 2,
          forecast_hour_start: isoTime + 'Z',
          forecast_hour_end: isoTimeTewntyFour + 'Z',
          format: "GeoJSON"
        })});
      const data = await response.json();
      /*setJsonData(JSON.stringify(data, null, 2));*/
      setJsonData(data);
    }
    fetchJsonData();
  }, []);

  if (!jsonData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <h2>C-17</h2>
    <table>
        <thead>
          <tr>
            <th>Forecast Hour</th>
            <th>Temperature Impact</th>
            <th>Visibility Impact</th>
            <th>Overall Impact</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(jsonData.features) && jsonData.features.map((feature) => (
            <tr key={feature.forecastHour}>
              <td>{feature.forecastHour}</td>
              <td style={
                feature.properties.parameters.temperatureAir.impact == 0
                  ? { backgroundColor: "green"}
                  : feature.properties.parameters.temperatureAir.impact == 1
                  ? { backgroundColor: "yellow" }
                  : { backgroundColor: "red"}
              }> {feature.properties.parameters.temperatureAir.impact}</td>
              <td style={
                feature.properties.parameters.visibilityPrevailing.impact == 0
                  ? { backgroundColor: "green"}
                  : feature.properties.parameters.visibilityPrevailing.impact == 1
                  ? { backgroundColor: "yellow" }
                  : { backgroundColor: "red"}
              }>{feature.properties.parameters.visibilityPrevailing.impact}</td>
              <td style={
                feature.properties.overall_impact == 0
                  ? { backgroundColor: "green"}
                  : feature.properties.overall_impact == 1
                  ? { backgroundColor: "yellow" }
                  : { backgroundColor: "red"}
              }>{feature.properties.overall_impact}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
  );
}

export default Impactsc17;
