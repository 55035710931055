import React, { useState } from 'react';
import AWS from 'aws-sdk';


const dynamodb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'AKIA46WRLH3YW3JHLFPG',
    secretAccessKey: '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',
  },
});

const ProductCountForm = () => {
  const [text, setText] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedProdOption, setSelectedProdOption] = useState('');
  const [selectedCountOption, setSelectedCountOption] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  function handleProdOptionChange(event) {
    setSelectedProdOption(event.target.value);
  }

  function handleCountOptionChange(event) {
    setSelectedCountOption(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const date = new Date();
      const isoDate = date.toISOString();
      
      const params = {
        TableName: 'osw_prod_count', // Replace with your table name
        Item: {
          user_name: { S: selectedOption }, // Replace with your item ID
          timestamp: { S: isoDate },
          product_type: { S: selectedProdOption },
          product_count: { S: selectedCountOption },
        },
      };
      await dynamodb.putItem(params).promise();
      console.log('Data inserted successfully!');
      setShowSuccessMessage(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="">-- Select a user --</option>
        <option value="Bill">Bill</option>
        <option value="Keven">Keven</option>
        <option value="John">John</option>
      </select>
      <select value={selectedProdOption} onChange={handleProdOptionChange}>
        <option value="">-- Select a product --</option>
        <option value="MEF">MEF</option>
        <option value="Sat">Sat</option>
        <option value="Radar">Radar</option>
      </select>
      <select value={selectedCountOption} onChange={handleCountOptionChange}>
        <option value="">-- Select count --</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
      <button type="submit">Submit</button>
      {showSuccessMessage && <p>Successfully inserted product count!</p>}
    </form>
  );
};

export default ProductCountForm;
